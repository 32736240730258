













import { Component, Vue } from 'vue-property-decorator';
import { H3Toast, H3Modal } from '@h3/thinking-ui';
import tabBar from './tabBar.vue';
Vue.use(H3Toast);
Vue.use(H3Modal);

@Component({
  name: 'mWorkPlatform',
  components: {
    tabBar,
  },
})
export default class mWorkPlatform extends Vue {
  get showTabBar () {
    return this.$route.meta && this.$route.meta.showTabBar;
  }
}
