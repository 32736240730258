































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'tabBar',
  components: {},
})
export default class ComponentName extends Vue {
  // 钉钉登录则不展示设置tab
  get isFreeLogin () {
    return sessionStorage.IS_DINGTALK || sessionStorage.IS_WECHAT;
  }
}
